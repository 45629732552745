
import { ROUTE_NAME_HOME, ROUTE_NAME_LOGIN } from '@/router'
import { authModule } from '@/store/modules/AuthModule'
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import PatientModule from '../store/modules/PatientModule'

@Component
export default class NavigationBar extends Vue {
  async logout () {
    return authModule.logout().then(() => {
      return this.$router.push({ name: ROUTE_NAME_LOGIN })
    })
  }

  async backHome (type: string) {
    this.$store.commit('PatientModule/TOGGLE_TAB', type)
    this.$store.commit('PatientModule/UPDATE_ORDER', '!date')
    this.$router.push({ name: ROUTE_NAME_HOME })
  }

  async toggleTab (type: string) {
    this.$store.commit('PatientModule/TOGGLE_TAB', type)
    this.$store.commit('PatientModule/UPDATE_ORDER', '!date')
    type === 'patients' ? this.fetchPatients() : this.fetchReceptionLogs()
  }

  isCurrentTab (type: string): boolean {
    return this.$store.getters['PatientModule/getTabType'] === type
  }

  async fetchPatients (): Promise<void> {
    const patientModule = getModule(PatientModule, this.$store)
    return patientModule.fetchPatients()
  }

  async fetchReceptionLogs (): Promise<void> {
    const patientModule = getModule(PatientModule, this.$store)
    return patientModule.fetchReceptionLogs()
  }
}
