
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import PatientModule from '../../store/modules/PatientModule'
import { PatientAttributes } from 'api/models'

@Component
export default class PaginationBar extends Vue {
  @Prop(Boolean) isLoading!: boolean
  patientModule = getModule(PatientModule, this.$store)

  get counts () { return this.patientModule.counts }
  get perPage () { return this.patientModule.perPage }
  get startNum () {
    return this.patientModule.getCurrentPage > 5
      ? this.patientModule.getCurrentPage - 5
      : 1
  }

  get endNum () {
    return this.patientModule.getCurrentPage < this.patientModule.getTotalPage - 5
      ? this.patientModule.getCurrentPage + 5
      : this.patientModule.getTotalPage
  }

  get rangeNum () {
    return Math.min(10, this.patientModule.getTotalPage)
  }

  private selectedOptions: object = [10, 20, 30, 40, 50]

  async fetchLists () {
    this.patientModule.getTabType === 'patients' ? this.fetchPatients() : this.fetchReceptionLogs()
  }

  async fetchPatients (): Promise<void> {
    return this.patientModule.fetchPatients()
  }

  async fetchReceptionLogs (): Promise<void> {
    return this.patientModule.fetchReceptionLogs()
  }

  async onFirstPage () {
    this.patientModule.FIRST_PAGE()
    this.fetchLists()
  }

  async onPrevPage () {
    this.patientModule.PREV_PAGE()
    this.fetchLists()
  }

  async onNextPage () {
    this.patientModule.NEXT_PAGE()
    this.fetchLists()
  }

  async onLastPage () {
    this.patientModule.LAST_PAGE()
    this.fetchLists()
  }

  async onSelectPage (pageNum: number) {
    this.patientModule.SELECT_PAGE(pageNum)
    this.fetchLists()
  }

  async onChange (event: Event) {
    const target = event.target as HTMLInputElement
    const perPage = Number(target.value.slice(0, -1))
    this.patientModule.CHANGE_PER_PAGE(perPage)
    this.fetchLists()
  }

  private selectedClass (index: number): string {
    return this.patientModule.getCurrentPage === index ? 'is_active' : ''
  }

  private displayPageNumbers (): number[] {
    return Array.from(Array(this.rangeNum).keys(), x => x + Math.max(this.endNum - this.rangeNum + 1, 1))
  }

  private displayedFirstArrow (): boolean {
    return Math.min(this.startNum, this.endNum - this.rangeNum + 1) > 1
  }

  private displayedPrevArrow (): boolean {
    return this.patientModule.getCurrentPage > 1
  }

  private displayedNextArrow (): boolean {
    return this.patientModule.getCurrentPage < this.patientModule.getTotalPage
  }

  private displayedLastArrow (): boolean {
    return Math.max(this.endNum, this.startNum + this.rangeNum - 1) < this.patientModule.getTotalPage
  }

  async downloadCSV (): Promise<void> {
    const type = this.patientModule.getTabType
    const patients = await this.fetchData(type)
    const patientsCSV = this.createCSV(patients, type)
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
    const blob = new Blob([bom, patientsCSV], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    const today = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000))
    const month = ('0' + (today.getMonth() + 1)).slice(-2)
    const date = ('0' + today.getDate()).slice(-2)
    const formatDate = `${today.getFullYear()}${month}${date}`
    a.href = url
    a.download = type === 'patients' ? `patient_list_${formatDate}.csv` : `reception_list_${formatDate}.csv`
    a.click()
  }

  private async fetchData (type: string): Promise<PatientAttributes []> {
    const limit = 10000
    let fetchedData: any
    let offset = 0
    let fetchedDataCount = 0
    let patients: PatientAttributes [] = []
    // 負荷軽減のため10000件ずつ取得
    if (type === 'patients') {
      do {
        fetchedData = await this.fetchPatientsForCSV(limit, offset)
        fetchedDataCount = fetchedData.counts
        offset += 10000
        patients.push(fetchedData.patients)
      } while (offset < fetchedDataCount)
    } else {
      do {
        fetchedData = await this.fetchReceptionLogsForCSV(limit, offset)
        fetchedDataCount = fetchedData.counts
        offset += 10000
        patients.push(fetchedData.receptionLogs)
      } while (offset < fetchedDataCount)
    }
    patients = patients.flat()
    return patients
  }

  private async fetchReceptionLogsForCSV (limit: number, offset: number): Promise<void> {
    const patientModule = getModule(PatientModule, this.$store)
    return patientModule.fetchReceptionLogsForCSV({ limit, offset })
  }

  private async fetchPatientsForCSV (limit: number, offset: number): Promise<void> {
    const patientModule = getModule(PatientModule, this.$store)
    return patientModule.fetchPatientsForCSV({ limit, offset })
  }

  private createCSV (patients: any, type: string): string {
    let headerKeys: string[] = []
    let keys: string[] = []
    if (type === 'patients') {
      headerKeys = ['登録日', '診察番号', '姓', '名', 'セイ', 'メイ', '生年月日', '性別', '電話番号', '登録済み保険種別', '顔認証登録状況']
      keys = ['createdAt', 'id', 'familyName', 'givenName', 'kanaFamilyName', 'kanaGivenName', 'birthday', 'isMale', 'telephone', 'insurance', 'personId']
    } else {
      headerKeys = ['受付日時', '診察番号', '姓', '名', 'セイ', 'メイ', '生年月日', '性別', '電話番号', '受付時保険種別', '受付方法']
      keys = ['createdAt', 'id', 'familyName', 'givenName', 'kanaFamilyName', 'kanaGivenName', 'birthday', 'isMale', 'telephone', 'insurance', 'receptionType']
    }
    const patientsCSV = [headerKeys.join(',')].concat(
      patients.map((data: any) => keys.map((key) => {
        let valueString = ''
        valueString = `"${data[key]}"`
        if (key === 'createdAt' && type === 'patients') {
          const date = new Date(data[key])
          valueString = `"${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}"`
        } else if (key === 'createdAt' && type === 'receptionLog') {
          const date = new Date(data[key])
          valueString = `"${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}"`
        } else if (key === 'isMale') {
          valueString = data[key] === true ? '"男"' : '"女"'
        } else if (key === 'insurance') {
          if (data[key] === 'healthInsurance') {
            valueString = '"健康保険"'
          } else if (data[key] === 'none') {
            valueString = '"自費診療"'
          } else {
            valueString = '""'
          }
        } else if (key === 'personId') {
          valueString = data[key] !== null ? '"登録"' : '"未登録"'
        } else if (key === 'receptionType') {
          valueString = data[key] === 'face' ? '"顔認証"' : '"診察券"'
        }
        return valueString
      }).join(','))
    )
    return patientsCSV.join('\r\n')
  }
}
