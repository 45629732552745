
import { Component, Vue } from 'vue-property-decorator'
import NavigationBar from '../components/NavigationBar.vue'
import Footer from '../components/Footer.vue'
import PatientEdit from '@/components/patientEdit/PatientEdit.vue'
import { getModule } from 'vuex-module-decorators'
import PatientModule from '@/store/modules/PatientModule'

@Component({
  components: {
    NavigationBar,
    PatientEdit,
    Footer
  }
})
export default class PatientEditView extends Vue {
  patientModule = getModule(PatientModule, this.$store)

  get isLoading () { return this.patientModule.isLoading }
  get currentPatient () { return this.patientModule.currentPatient }

  async created (): Promise<void> {
    await this.fetchPatient()
  }

  async fetchPatient (): Promise<void> {
    const id = this.$route.params.id
    await this.patientModule.fetchPatient(id)
  }
}
