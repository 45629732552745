
import { Vue, Component } from 'vue-property-decorator'
import NavigationBar from '@/components/NavigationBar.vue'
import PatientDetail from '@/components/patients/PatientDetail.vue'
import Footer from '@/components/Footer.vue'
import { getModule } from 'vuex-module-decorators'
import PatientModule from '@/store/modules/PatientModule'

@Component({
  components: {
    NavigationBar,
    PatientDetail,
    Footer
  }
})
export default class PatientDetailView extends Vue {
  patientModule = getModule(PatientModule, this.$store)

  get isLoading () { return this.patientModule.isLoading }
  get currentPatient () { return this.patientModule.currentPatient }

  async created (): Promise<void> {
    this.fetchPatient()
  }

  async fetchPatient (): Promise<void> {
    const id = this.$route.params.id
    await this.patientModule.fetchPatient(id)
  }
}

