import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import PatientEditView from '../views/PatientEditView.vue'
import PatientNewView from '../views/PatientNewView.vue'

import { isLoggedIn } from '@/store/modules/AuthModule'
import PatientDetail from '../views/PatientDetail.vue'

Vue.use(VueRouter)

export const ROUTE_PATH_HOME = '/'
export const ROUTE_NAME_HOME = 'Home'
export const ROUTE_NAME_NEW = 'PatientNew'
export const ROUTE_NAME_EDIT = 'PatientEdit'
export const ROUTE_NAME_LOGIN = 'Login'
const ROUTE_NAME_PATIENT = 'Patient'

const routes: Array<RouteConfig> = [
  {
    path: ROUTE_PATH_HOME,
    name: ROUTE_NAME_HOME,
    component: Home
  },
  {
    path: '/patient/new',
    name: ROUTE_NAME_NEW,
    component: PatientNewView
  },
  {
    path: '/patient/:id/edit',
    name: ROUTE_NAME_EDIT,
    component: PatientEditView
  },
  {
    path: '/login',
    name: ROUTE_NAME_LOGIN,
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/patient/:id',
    name: ROUTE_NAME_PATIENT,
    component: PatientDetail,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// ログイン確認
router.beforeEach((to, from, next) => {
  if ((to.name === ROUTE_NAME_LOGIN) && isLoggedIn()) {
    // ログインされていたらlogin画面遷移せずにホームに異動
    next({ name: ROUTE_NAME_HOME })
  } else if ((to.name !== ROUTE_NAME_LOGIN) && !isLoggedIn()) {
    next({ name: ROUTE_NAME_LOGIN })
  } else {
    next()
  }
})

export default router
