
import PatientModule from '@/store/modules/PatientModule'
import { PatientAttributes } from 'api/models'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component
export default class DeleteConfirmModal extends Vue {
  @Prop()
  public patient!: PatientAttributes

  @Prop()
  public show!: boolean

  deletePatient () {
    const patientModule = getModule(PatientModule, this.$store)
    patientModule.deletePatient(this.patient.id).then(() => {
      this.cancel()
      this.$store.commit('PatientModule/TOGGLE_DELETE_COMPLETE_MODAL', true)
    })
  }

  cancel () {
    this.$store.commit('PatientModule/TOGGLE_DELETE_CONFIRM_MODAL', false)
  }
}
