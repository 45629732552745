
import { PatientAttributes } from 'api/models'
import { ROUTE_NAME_EDIT } from '@/router'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

@Component({
  components: {
    LoadingSpinner
  }
})
export default class PatientDetail extends Vue {
  @Prop(Boolean) isLoading!: boolean
  @Prop() public patient!: PatientAttributes

  insertHyphen (str: string) {
    const res = str.slice(0, 3) + '-' + str.slice(3)
    return res
  }

  convertHyphen (str: string) {
    const res = str.replaceAll('-', '/')
    return res
  }

  clickEdit () {
    this.$router.push({
      name: ROUTE_NAME_EDIT,
      params: { id: this.patient.id }
    })
  }
}
