
import { ROUTE_NAME_HOME } from '@/router'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EditCompleteModal extends Vue {
  @Prop()
  public show!: boolean

  isNewPage (): boolean {
    return this.$route.path === '/patient/new'
  }

  close () {
    this.$store.commit('PatientModule/TOGGLE_EDIT_COMPLETE_MODAL', false)
    this.$router.push({ name: ROUTE_NAME_HOME })
  }
}
