import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import { ROUTE_PATH_HOME } from '@/router'
import store from '@/store/index'

Vue.use(Vuex)
Vue.use(VueCookies)

const JWT_PAYLOAD = 'token'

export interface AuthState {
  isLoading: boolean // ログインAPIの回答待ちか
}

@Module({ dynamic: true, store, name: 'AuthModule', namespaced: true })
export default class AuthModule extends VuexModule implements AuthState {
  isLoading = false

  @Mutation
  public SET_IS_LOADING (isLoading: boolean) {
    this.isLoading = isLoading
  }

  @Action({})
  public async login (param: {email: string, password: string, setExpires: boolean }) {
    this.SET_IS_LOADING(true)
    return axios.post('/api/login', {
      email: param.email,
      password: param.password,
      setExpires: param.setExpires,
      setCookie: true
    }).then(
    ).catch((reason) => {
      throw reason
    }).finally(() => {
      this.SET_IS_LOADING(false)
    })
  }

  @Action({})
  async logout () {
    // token 削除
    Vue.$cookies.remove(JWT_PAYLOAD, ROUTE_PATH_HOME, document.domain)
  }
}

/**
 * ログインされているか
 * @returns boolean
 */
export const isLoggedIn = (): boolean => {
  return Boolean(Vue.$cookies.get(JWT_PAYLOAD))
}

export const authModule = getModule(AuthModule)
