
import { ROUTE_NAME_NEW } from '@/router'
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import PatientModule from '../../store/modules/PatientModule'

@Component
export default class SearchWindow extends Vue {
  private word = this.$store.getters['PatientModule/getSearchWord']
  private dateLowerLimit = this.$store.getters['PatientModule/getDateLowerLimit']
  private dateUpperLimit = this.$store.getters['PatientModule/getDateUpperLimit']
  private isMale = (this.$store.getters['PatientModule/getIsMale'] === 1)
  private isFemale = (this.$store.getters['PatientModule/getIsFemale'] === 1)
  private isHealthInsurance = (this.$store.getters['PatientModule/getIsHealthInsurance'] === 1)
  private isNoInsurance = (this.$store.getters['PatientModule/getisNoInsurance'] === 1)
  private isFaceRegistration = (this.$store.getters['PatientModule/getIsFaceRegistration'] === 1)
  private isFaceUnregistration = (this.$store.getters['PatientModule/getIsFaceUnRegistration'] === 1)
  private isFaceAuthentication = (this.$store.getters['PatientModule/getIsFaceAuthentication'] === 1)
  private isIdAuthentication = (this.$store.getters['PatientModule/getIsIdAuthentication'] === 1)

  newPatient () {
    this.$router.push({ name: ROUTE_NAME_NEW })
  }

  get isPatientsTab (): boolean {
    return this.$store.getters['PatientModule/getTabType'] === 'patients'
  }

  async fetchLists () {
    this.$store.getters['PatientModule/getTabType'] === 'patients' ? this.fetchPatients() : this.fetchReceptionLogs()
  }

  async fetchPatients (): Promise<void> {
    const patientModule = getModule(PatientModule, this.$store)
    return patientModule.fetchPatients()
  }

  async fetchReceptionLogs (): Promise<void> {
    const patientModule = getModule(PatientModule, this.$store)
    return patientModule.fetchReceptionLogs()
  }

  async onSearchWord () {
    this.$store.commit('PatientModule/SEARCH_WORD', this.word)
    this.$store.commit('PatientModule/SEARCH_DATE_LOWER_LIMIT', '')
    this.$store.commit('PatientModule/SEARCH_DATE_UPPER_LIMIT', '')
    this.$store.commit('PatientModule/SEARCH_IS_MALE', 1)
    this.$store.commit('PatientModule/SEARCH_IS_FEMALE', 1)
    this.$store.commit('PatientModule/SEARCH_IS_FACE_REGISTRATION', 1)
    this.$store.commit('PatientModule/SEARCH_IS_FACE_UNREGISTRATION', 1)
    this.$store.commit('PatientModule/SEARCH_IS_FACE_AUTHENTICATION', 1)
    this.$store.commit('PatientModule/SEARCH_IS_ID_AUTHENTICATION', 1)
    this.$store.commit('PatientModule/SEARCH_IS_NATIONAL_INSURANCE', 1)
    this.$store.commit('PatientModule/SEARCH_IS_SOCIAL_INSURANCE', 1)
    this.$store.commit('PatientModule/SEARCH_IS_NO_INSURANCE', 1)
    this.fetchLists()
  }

  async onSearchOthers () {
    this.$store.commit('PatientModule/SEARCH_WORD', '')
    this.$store.commit('PatientModule/SEARCH_DATE_LOWER_LIMIT', this.dateLowerLimit)
    this.$store.commit('PatientModule/SEARCH_DATE_UPPER_LIMIT', this.dateUpperLimit)
    this.$store.commit('PatientModule/SEARCH_IS_MALE', this.isMale ? 1 : 0)
    this.$store.commit('PatientModule/SEARCH_IS_FEMALE', this.isFemale ? 1 : 0)
    this.$store.commit('PatientModule/SEARCH_IS_FACE_REGISTRATION', this.isFaceRegistration ? 1 : 0)
    this.$store.commit('PatientModule/SEARCH_IS_FACE_UNREGISTRATION', this.isFaceUnregistration ? 1 : 0)
    this.$store.commit('PatientModule/SEARCH_IS_FACE_AUTHENTICATION', this.isFaceAuthentication ? 1 : 0)
    this.$store.commit('PatientModule/SEARCH_IS_ID_AUTHENTICATION', this.isIdAuthentication ? 1 : 0)
    this.$store.commit('PatientModule/SEARCH_IS_HEALTH_INSURANCE', this.isHealthInsurance ? 1 : 0)
    this.$store.commit('PatientModule/SEARCH_IS_NO_INSURANCE', this.isNoInsurance ? 1 : 0)
    this.fetchLists()
  }

  async trigger () {
    this.onSearchWord()
  }
}
