
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ROUTE_NAME_EDIT } from '@/router'
import { getModule } from 'vuex-module-decorators'
import PatientModule from '@/store/modules/PatientModule'

Component.registerHooks([
  'beforeMount',
  'beforeUpdate',
  'updated'
])
@Component
export default class PatientList extends Vue {
  @Prop(Boolean) isLoading!: boolean

  patients = []

  order = '!date'

  // XXX isCurrentTabと共通化できるか？
  get isPatientsTab (): boolean {
    return this.$store.getters['PatientModule/getTabType'] === 'patients'
  }

  async filterPatients (): Promise<void> {
    this.patients = this.$store.getters['PatientModule/getFilterPatients']
  }

  async beforeMount (): Promise<void> {
    await this.filterPatients()
  }

  formatDate (createdAt: string) {
    const date = new Date(createdAt)
    if (this.isPatientsTab) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } else {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`
    }
  }

  formatBirthDay (date: string) {
    return date.replaceAll('-', '/')
  }

  editPatient (patientId: string) {
    this.$router.push({ name: ROUTE_NAME_EDIT, params: { id: patientId } })
  }

  async sort (order: string): Promise<void> {
    const orderWithDirection = await this.setOrderDirection(order)
    this.$store.commit('PatientModule/UPDATE_ORDER', orderWithDirection)
    this.fetchLists()
  }

  async setOrderDirection (order: string) {
    const defaultOrder = 'date'
    switch (order) {
      case 'date':
        await this.changeOrderDirection(this.$store.getters['PatientModule/getDateOrderDirection'], 'UPDATE_DATE_ORDER_DIRECTION')
        return `${this.$store.getters['PatientModule/getDateOrderDirection']}${defaultOrder}`
      case 'id':
        await this.changeOrderDirection(this.$store.getters['PatientModule/getIdOrderDirection'], 'UPDATE_ID_ORDER_DIRECTION')
        return `${this.$store.getters['PatientModule/getIdOrderDirection']}${order},!${defaultOrder}`
      case 'name':
        await this.changeOrderDirection(this.$store.getters['PatientModule/getNameOrderDirection'], 'UPDATE_NAME_ORDER_DIRECTION')
        return `${this.$store.getters['PatientModule/getNameOrderDirection']}${order},!${defaultOrder}`
      case 'birthday':
        await this.changeOrderDirection(this.$store.getters['PatientModule/getBirthdayOrderDirection'], 'UPDATE_BIRTHDAY_ORDER_DIRECTION')
        return `${this.$store.getters['PatientModule/getBirthdayOrderDirection']}${order},!${defaultOrder}`
      case 'sex':
        await this.changeOrderDirection(this.$store.getters['PatientModule/getSexOrderDirection'], 'UPDATE_SEX_ORDER_DIRECTION')
        return `${this.$store.getters['PatientModule/getSexOrderDirection']}${order},!${defaultOrder}`
      case 'telephone':
        await this.changeOrderDirection(this.$store.getters['PatientModule/getTelephoneOrderDirection'], 'UPDATE_TELEPHONE_ORDER_DIRECTION')
        return `${this.$store.getters['PatientModule/getTelephoneOrderDirection']}${order},!${defaultOrder}`
      case 'insurance':
        await this.changeOrderDirection(this.$store.getters['PatientModule/getInsuranceOrderDirection'], 'UPDATE_INSURANCE_ORDER_DIRECTION')
        return `${this.$store.getters['PatientModule/getInsuranceOrderDirection']}${order},!${defaultOrder}`
      case 'receptionType':
        await this.changeOrderDirection(this.$store.getters['PatientModule/getReceptionTypeOrderDirection'], 'UPDATE_RECEPTIONTYPE_ORDER_DIRECTION')
        return `${this.$store.getters['PatientModule/getReceptionTypeOrderDirection']}${order},!${defaultOrder}`
      case 'face':
        await this.changeOrderDirection(this.$store.getters['PatientModule/getFaceOrderDirection'], 'UPDATE_FACE_ORDER_DIRECTION')
        return `${this.$store.getters['PatientModule/getFaceOrderDirection']}${order},!${defaultOrder}`
    }
  }

  /**
   * orderの第一項目が登録日(受付日時)のASCか
   */
  isOrderDateAsc (order: string) { return order.match(/^date/) }

  /**
   * orderの第一項目が登録日(受付日時)のDESCか
   */
  isOrderDateDesc (order: string) { return order.match(/^!date/) }

  async changeOrderDirection (orderDirection: string, targetMutationMethod: string) {
    const targetMutation = `PatientModule/${targetMutationMethod}`
    if (orderDirection === '!') {
      this.$store.commit(targetMutation, '')
    } else {
      this.$store.commit(targetMutation, '!')
    }
  }

  async fetchLists () {
    this.isPatientsTab ? this.fetchPatients() : this.fetchReceptionLogs()
  }

  async fetchPatients (): Promise<void> {
    const patientModule = getModule(PatientModule, this.$store)
    return patientModule.fetchPatients()
  }

  async fetchReceptionLogs (): Promise<void> {
    const patientModule = getModule(PatientModule, this.$store)
    return patientModule.fetchReceptionLogs()
  }

  beforeUpdate () {
    this.order = this.$store.getters['PatientModule/getOrder']
  }
}
