import axios, { AxiosResponse } from 'axios'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { PatientAttributes } from '../../../api/models'

const DEFAULT_CURRENT_PAGE = 1
const DEFAULT_PER_PAGE = 10

@Module({ name: 'PatientModule', stateFactory: true, namespaced: true })
export default class PatientModule extends VuexModule {
  isLoading = true // 患者取得中であるか
  patients: PatientAttributes[] = []
  currentPatient: Partial<PatientAttributes> = {}
  counts = 0
  tab = 'receptionLog'
  showDeleteConfirm = false
  showDeleteComplete = false
  showEditComplete = false
  currentPage = DEFAULT_CURRENT_PAGE
  totalPage = 1
  perPage = DEFAULT_PER_PAGE
  searchType = 'freeword'
  searchWord = ''
  searchParams = {
    dateLowerLimit: '',
    dateUpperLimit: '',
    isMale: 1,
    isFemale: 1,
    isHealthInsurance: 1,
    isNoInsurance: 1,
    isFaceRegistration: 1,
    isFaceUnregistration: 1,
    isFaceAuthentication: 1,
    isIdAuthentication: 1
  }

  order = '!date'
  orderDirections = {
    date: '!',
    id: '',
    name: '',
    birthday: '',
    sex: '',
    telephone: '',
    insurance: '',
    receptionType: '',
    face: ''
  }

  get getTabType () {
    return this.tab
  }

  get getSearchType () {
    return this.searchType
  }

  get getPatients () {
    return this.patients
  }

  get getPatient () {
    return this.currentPatient
  }

  get showEditModal () {
    return this.showEditComplete
  }

  get showDeleteCompleteModal () {
    return this.showDeleteComplete
  }

  get showDeleteConfirmModal () {
    return this.showDeleteConfirm
  }

  get showModal () {
    return this.showDeleteConfirm || this.showDeleteComplete || this.showEditComplete
  }

  get getFilterPatients () {
    return this.patients.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
  }

  get getCurrentPage () {
    return this.currentPage
  }

  get getTotalPage () {
    return this.totalPage
  }

  get getPerPage () {
    return this.perPage
  }

  get getSearchWord () {
    return this.searchWord
  }

  get getDateLowerLimit () {
    return this.searchParams.dateLowerLimit
  }

  get getDateUpperLimit () {
    return this.searchParams.dateUpperLimit
  }

  get getIsFaceRegistration () {
    return this.searchParams.isFaceRegistration
  }

  get getIsFaceUnRegistration () {
    return this.searchParams.isFaceUnregistration
  }

  get getIsFaceAuthentication () {
    return this.searchParams.isFaceRegistration
  }

  get getIsIdAuthentication () {
    return this.searchParams.isFaceUnregistration
  }

  get getIsMale () {
    return this.searchParams.isMale
  }

  get getIsFemale () {
    return this.searchParams.isFemale
  }

  get getIsHealthInsurance () {
    return this.searchParams.isHealthInsurance
  }

  get getisNoInsurance () {
    return this.searchParams.isNoInsurance
  }

  get getOrder () {
    return this.order
  }

  get getDateOrderDirection () {
    return this.orderDirections.date
  }

  get getIdOrderDirection () {
    return this.orderDirections.id
  }

  get getNameOrderDirection () {
    return this.orderDirections.name
  }

  get getBirthdayOrderDirection () {
    return this.orderDirections.birthday
  }

  get getSexOrderDirection () {
    return this.orderDirections.sex
  }

  get getTelephoneOrderDirection () {
    return this.orderDirections.telephone
  }

  get getInsuranceOrderDirection () {
    return this.orderDirections.insurance
  }

  get getReceptionTypeOrderDirection () {
    return this.orderDirections.receptionType
  }

  get getFaceOrderDirection () {
    return this.orderDirections.face
  }

  @Mutation
  public SET_IS_LOADING (isLoading: boolean) { this.isLoading = isLoading }

  @Mutation
  public FETCH_PATIENTS (patients: PatientAttributes[]): void {
    this.patients = patients
    this.totalPage = Math.ceil(this.counts / this.perPage) > 0 ? Math.ceil(this.counts / this.perPage) : 1
  }

  @Mutation
  public FETCH_PATIENT (patient: PatientAttributes): void {
    this.currentPatient = patient
  }

  @Mutation
  public UPDATE_PATIENT (patient: PatientAttributes): void {
    this.patients = this.patients.map(p => {
      if (p.id === patient.id) {
        p = patient
      }
      return p
    })
  }

  @Mutation
  public TOGGLE_EDIT_COMPLETE_MODAL (show: boolean): void {
    this.showEditComplete = show
  }

  @Mutation
  public TOGGLE_DELETE_CONFIRM_MODAL (show: boolean): void {
    this.showDeleteConfirm = show
  }

  @Mutation
  public TOGGLE_DELETE_COMPLETE_MODAL (show: boolean): void {
    this.showDeleteComplete = show
  }

  @Mutation
  public DELETE_PATIENT (id: string): void {
    this.patients = this.patients.filter(patient => patient.id !== id)
  }

  @Mutation
  public TOGGLE_TAB (type: string): void {
    this.tab = type
    this.currentPage = DEFAULT_CURRENT_PAGE
    this.perPage = DEFAULT_PER_PAGE
  }

  @Mutation
  public SEARCH_TYPE (type: string): void {
    this.searchType = type
  }

  @Mutation
  public COUNTS (counts: number): void {
    this.counts = counts
  }

  @Mutation
  public FIRST_PAGE (): void {
    this.currentPage = DEFAULT_CURRENT_PAGE
  }

  @Mutation
  public PREV_PAGE (): void {
    this.currentPage = Math.max(this.currentPage - 1, 1)
  }

  @Mutation
  public NEXT_PAGE (): void {
    this.currentPage = Math.min(this.currentPage + 1, this.totalPage)
  }

  @Mutation
  public LAST_PAGE (): void {
    this.currentPage = this.totalPage
  }

  @Mutation
  public SELECT_PAGE (pageNum: number): void {
    this.currentPage = pageNum
  }

  @Mutation
  public CHANGE_PER_PAGE (perPage: number): void {
    this.perPage = perPage
    this.currentPage = DEFAULT_CURRENT_PAGE
  }

  @Mutation
  public SEARCH_WORD (searchWord: string): void {
    this.searchWord = searchWord
  }

  @Mutation
  public SEARCH_DATE_LOWER_LIMIT (dateLowerLimit: string): void {
    this.searchParams.dateLowerLimit = dateLowerLimit
  }

  @Mutation
  public SEARCH_DATE_UPPER_LIMIT (dateUpperLimit: string): void {
    this.searchParams.dateUpperLimit = dateUpperLimit
  }

  @Mutation
  public SEARCH_IS_MALE (isMale: number): void {
    this.searchParams.isMale = isMale
  }

  @Mutation
  public SEARCH_IS_FEMALE (isFemale: number): void {
    this.searchParams.isFemale = isFemale
  }

  @Mutation
  public SEARCH_IS_FACE_REGISTRATION (isFaceRegistration: number): void {
    this.searchParams.isFaceRegistration = isFaceRegistration
  }

  @Mutation
  public SEARCH_IS_FACE_UNREGISTRATION (isFaceUnregistration: number): void {
    this.searchParams.isFaceUnregistration = isFaceUnregistration
  }

  @Mutation
  public SEARCH_IS_FACE_AUTHENTICATION (isFaceAuthentication: number): void {
    this.searchParams.isFaceAuthentication = isFaceAuthentication
  }

  @Mutation
  public SEARCH_IS_ID_AUTHENTICATION (isIdAuthentication: number): void {
    this.searchParams.isIdAuthentication = isIdAuthentication
  }

  @Mutation
  public SEARCH_IS_HEALTH_INSURANCE (isHealthInsurance: number): void {
    this.searchParams.isHealthInsurance = isHealthInsurance
  }

  @Mutation
  public SEARCH_IS_NO_INSURANCE (isNoInsurance: number): void {
    this.searchParams.isNoInsurance = isNoInsurance
  }

  @Mutation
  public UPDATE_ORDER (order: string): void {
    this.order = order
  }

  @Mutation
  public UPDATE_DATE_ORDER_DIRECTION (direction: string): void {
    this.orderDirections.date = direction
  }

  @Mutation
  public UPDATE_ID_ORDER_DIRECTION (direction: string): void {
    this.orderDirections.id = direction
  }

  @Mutation
  public UPDATE_NAME_ORDER_DIRECTION (direction: string): void {
    this.orderDirections.name = direction
  }

  @Mutation
  public UPDATE_BIRTHDAY_ORDER_DIRECTION (direction: string): void {
    this.orderDirections.birthday = direction
  }

  @Mutation
  public UPDATE_SEX_ORDER_DIRECTION (direction: string): void {
    this.orderDirections.sex = direction
  }

  @Mutation
  public UPDATE_TELEPHONE_ORDER_DIRECTION (direction: string): void {
    this.orderDirections.telephone = direction
  }

  @Mutation
  public UPDATE_INSURANCE_ORDER_DIRECTION (direction: string): void {
    this.orderDirections.insurance = direction
  }

  @Mutation
  public UPDATE_RECEPTIONTYPE_ORDER_DIRECTION (direction: string): void {
    this.orderDirections.receptionType = direction
  }

  @Mutation
  public UPDATE_FACE_ORDER_DIRECTION (direction: string): void {
    this.orderDirections.face = direction
  }

  @Action({ rawError: true })
  async fetchPatients (): Promise<void> {
    this.SET_IS_LOADING(true)
    return axios
      .get('/api/patients', {
        params: {
          limit: this.perPage,
          offset: (this.currentPage - 1) * this.perPage,
          word: this.searchWord,
          dateLowerLimit: this.searchParams.dateLowerLimit,
          dateUpperLimit: this.searchParams.dateUpperLimit,
          isMale: this.searchParams.isMale,
          isFemale: this.searchParams.isFemale,
          isHealthInsurance: this.searchParams.isHealthInsurance,
          isNoInsurance: this.searchParams.isNoInsurance,
          isFaceRegistration: this.searchParams.isFaceRegistration,
          isFaceUnregistration: this.searchParams.isFaceUnregistration,
          order: this.order
        }
      })
      .then((response: AxiosResponse) => {
        this.COUNTS(response.data.counts)
        this.FETCH_PATIENTS(response.data.patients)
        return Promise.resolve(response.data && response.data.patients)
      }).catch((reason) => {
        return Promise.reject(reason)
      }).finally(() => {
        this.SET_IS_LOADING(false)
      })
  }

  @Action({ rawError: true })
  async fetchPatient (id: string): Promise<void> {
    this.SET_IS_LOADING(true)
    return axios
      .get('/api/patient/' + id)
      .then((response: AxiosResponse) => {
        this.FETCH_PATIENT(response.data)
        return Promise.resolve()
      }).catch((reason) => {
        return Promise.reject(reason)
      }).finally(() => {
        this.SET_IS_LOADING(false)
      })
  }

  @Action({ rawError: true })
  async fetchReceptionLogs (): Promise<void> {
    this.SET_IS_LOADING(true)
    return axios
      .get('/api/receptionLogs', {
        params: {
          limit: this.perPage,
          offset: (this.currentPage - 1) * this.perPage,
          word: this.searchWord,
          dateLowerLimit: this.searchParams.dateLowerLimit,
          dateUpperLimit: this.searchParams.dateUpperLimit,
          isHealthInsurance: this.searchParams.isHealthInsurance,
          isNoInsurance: this.searchParams.isNoInsurance,
          isFaceAuthentication: this.searchParams.isFaceAuthentication,
          isIdAuthentication: this.searchParams.isIdAuthentication,
          order: this.order
        }
      })
      .then((response: AxiosResponse) => {
        this.COUNTS(response.data.counts)
        this.FETCH_PATIENTS(response.data.receptionLogs)
        return Promise.resolve(response.data && response.data.receptionLogs)
      }).catch((reason) => {
        return Promise.reject(reason)
      }).finally(() => {
        this.SET_IS_LOADING(false)
      })
  }

  @Action({ rawError: true })
  // XXX updatePatient方作成? もしくはPatientAttributesの修正などで対応できるか
  async updatePatient (patient: {
    id: string
    familyName: string
    kanaFamilyName: string
    givenName: string
    kanaGivenName: string
    birthday: Date|string
    insurance: string
    telephone: string
    postalCode: string
    isMale: boolean
    memo: string|null
  }): Promise<void> {
    this.SET_IS_LOADING(true)
    return axios
      .patch(`/api/patient/${patient.id}`, patient)
      .then(() => {
        return Promise.resolve()
      }).catch((reason) => {
        return Promise.reject(reason)
      }).finally(() => {
        this.SET_IS_LOADING(false)
      })
  }

  @Action({ rawError: true })
  async createPatient (patient: {
    id: string
    familyName: string
    kanaFamilyName: string
    givenName: string
    kanaGivenName: string
    birthday: Date|string
    insurance: string
    telephone: string
    postalCode: string
    isMale: boolean
    memo: string|null
  }): Promise<void> {
    this.SET_IS_LOADING(true)
    return axios
      .post('/api/patient', patient)
      .then(() => {
        return Promise.resolve()
      }).catch((reason) => {
        return Promise.reject(reason)
      }).finally(() => {
        this.SET_IS_LOADING(false)
      })
  }

  @Action({ rawError: true })
  async deletePatient (id: string): Promise<void> {
    this.SET_IS_LOADING(true)
    return axios
      .delete(`/api/patient/${id}`)
      .then(() => {
        this.DELETE_PATIENT(id)
        return Promise.resolve()
      }).catch((reason) => {
        return Promise.reject(reason)
      }).finally(() => {
        this.SET_IS_LOADING(false)
      })
  }

  @Action({ rawError: true })
  async fetchPatientsForCSV ({ limit, offset }: {limit: number, offset: number}): Promise<void> {
    return axios
      .get('/api/patients', {
        params: {
          limit,
          offset,
          word: this.searchWord,
          dateLowerLimit: this.searchParams.dateLowerLimit,
          dateUpperLimit: this.searchParams.dateUpperLimit,
          isMale: this.searchParams.isMale,
          isFemale: this.searchParams.isFemale,
          isHealthInsurance: this.searchParams.isHealthInsurance,
          isNoInsurance: this.searchParams.isNoInsurance,
          isFaceRegistration: this.searchParams.isFaceRegistration,
          isFaceUnregistration: this.searchParams.isFaceUnregistration,
          order: this.order
        }
      })
      .then((response: AxiosResponse) => {
        console.log(response.data)
        return Promise.resolve(response.data)
      }).catch((reason) => {
        return Promise.reject(reason)
      })
  }

  @Action({ rawError: true })
  async fetchReceptionLogsForCSV ({ limit, offset }: {limit: number, offset: number}): Promise<void> {
    return axios
      .get('/api/receptionLogs', {
        params: {
          limit,
          offset,
          word: this.searchWord,
          dateLowerLimit: this.searchParams.dateLowerLimit,
          dateUpperLimit: this.searchParams.dateUpperLimit,
          isHealthInsurance: this.searchParams.isHealthInsurance,
          isNoInsurance: this.searchParams.isNoInsurance,
          isFaceAuthentication: this.searchParams.isFaceAuthentication,
          isIdAuthentication: this.searchParams.isIdAuthentication,
          order: this.order
        }
      }).then((response: AxiosResponse) => {
        return Promise.resolve(response.data)
      }).catch((reason) => {
        return Promise.reject(reason)
      })
  }
}
