
import { Component, Vue } from 'vue-property-decorator'
import NavigationBar from '@/components/NavigationBar.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import Footer from '@/components/Footer.vue'
import PatientList from '@/components/patients/PatientList.vue'
import PaginationBar from '@/components/patients/PaginationBar.vue'
import SearchWindow from '@/components/patients/SearchWindow.vue'
import { getModule } from 'vuex-module-decorators'
import PatientModule from '@/store/modules/PatientModule'

@Component({
  components: {
    NavigationBar,
    PatientList,
    PaginationBar,
    SearchWindow,
    LoadingSpinner,
    Footer
  }
})
export default class Home extends Vue {
  patientModule = getModule(PatientModule, this.$store)

  get isLoading () { return this.patientModule.isLoading }

  async created (): Promise<void> {
    this.isPatientsTab() ? this.fetchPatients() : this.fetchReceptionLogs()
  }

  isPatientsTab (): boolean {
    return this.$store.getters['PatientModule/getTabType'] === 'patients'
  }

  async fetchReceptionLogs (): Promise<void> {
    this.patientModule.fetchReceptionLogs()
  }

  async fetchPatients (): Promise<void> {
    this.patientModule.fetchPatients()
  }
}
