
import { Component, Prop, Vue } from 'vue-property-decorator'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import EditCompleteModal from './EditCompleteModal.vue'
import DeleteCompleteModal from './DeleteCompleteModal.vue'
import DeleteConfirmModal from './DeleteConfirmModal.vue'
import { ROUTE_NAME_HOME } from '@/router'
import { getModule } from 'vuex-module-decorators'
import PatientModule from '@/store/modules/PatientModule'
import { PatientAttributes } from 'api/models'

Component.registerHooks([
  'beforeUpdate'
])
@Component({
  components: {
    LoadingSpinner,
    EditCompleteModal,
    DeleteCompleteModal,
    DeleteConfirmModal
  }
})
export default class PatientEdit extends Vue {
  @Prop(Boolean) isLoading!: boolean
  @Prop() patient!: PatientAttributes

  postalCode3digit = ''

  postalCode4digit = ''

  birthYear = 1960

  birthMonth = 1

  birthDate = 1

  isLoad = false

  errorNum = 0

  isCreateButtonClicked = false

  isServerError = false

  isIdInputted = false

  isFamiliyNameInputted = false

  isGivenNameInputted = false

  isKanaFamiliyNameInputted = false

  isKanaGivenNameInputted = false

  isTelephoneInputted = false

  isPostalCode3digitInputted = false

  isPostalCode4digitInputted = false

  backHome () {
    this.$router.push({ name: ROUTE_NAME_HOME })
  }

  createComplete () {
    this.isCreateButtonClicked = true
    this.updateErrorNum()
    if (this.errorNum > 0) {
      return
    }

    const patientModule = getModule(PatientModule, this.$store)
    patientModule.createPatient({
      id: this.patient.id,
      familyName: this.patient.familyName,
      kanaFamilyName: this.patient.kanaFamilyName,
      givenName: this.patient.givenName,
      kanaGivenName: this.patient.kanaGivenName,
      birthday: this.birthYear.toString() + '-' + this.birthMonth.toString() + '-' + this.birthDate.toString(),
      insurance: this.patient.insurance,
      telephone: this.patient.telephone,
      postalCode: this.postalCode3digit + this.postalCode4digit,
      isMale: this.patient.isMale,
      memo: this.patient.memo
    }).then(() => {
      this.isServerError = false
      this.$store.commit('PatientModule/TOGGLE_EDIT_COMPLETE_MODAL', true)
    }).catch((e) => {
      console.log(e)
      this.isServerError = true
    })
  }

  showEditComplete () {
    if (this.errorNum > 0) {
      return
    }

    const patientModule = getModule(PatientModule, this.$store)
    patientModule.updatePatient({
      id: this.patient.id,
      familyName: this.patient.familyName,
      kanaFamilyName: this.patient.kanaFamilyName,
      givenName: this.patient.givenName,
      kanaGivenName: this.patient.kanaGivenName,
      birthday: this.birthYear.toString() + '-' + this.birthMonth.toString() + '-' + this.birthDate.toString(),
      insurance: this.patient.insurance,
      telephone: this.patient.telephone,
      postalCode: this.postalCode3digit + this.postalCode4digit,
      isMale: this.patient.isMale,
      memo: this.patient.memo
    }).then(() => {
      this.$store.commit('PatientModule/TOGGLE_EDIT_COMPLETE_MODAL', true)
    })
  }

  isNewPage (): boolean {
    return this.$route.path === '/patient/new'
  }

  checkErrorId () {
    if (this.isNewPage()) {
      return this.isCreateButtonClicked && (this.patient.id.length !== 4 || !this.patient.id.match(/^[0-9]+$/))
    } else {
      return this.patient.id.length !== 4 || !this.patient.id.match(/^[0-9]+$/)
    }
  }

  checkErrorFamilyName () {
    if (this.isNewPage()) {
      return this.isCreateButtonClicked && !this.patient.familyName
    } else {
      return !this.patient.familyName
    }
  }

  checkErrorGivenName () {
    if (this.isNewPage()) {
      return this.isCreateButtonClicked && !this.patient.givenName
    } else {
      return !this.patient.givenName
    }
  }

  checkErrorKanaFamilyName () {
    if (this.isNewPage()) {
      return this.isCreateButtonClicked && (!this.patient.kanaFamilyName || !this.patient.kanaFamilyName.match(/^[ァ-ヶー]*$/))
    } else {
      return !this.patient.kanaFamilyName || !this.patient.kanaFamilyName.match(/^[ァ-ヶー]*$/)
    }
  }

  checkErrorKanaGivenName () {
    if (this.isNewPage()) {
      return this.isCreateButtonClicked && (!this.patient.kanaGivenName || !this.patient.kanaGivenName.match(/^[ァ-ヶー]*$/))
    } else {
      return !this.patient.kanaGivenName || !this.patient.kanaGivenName.match(/^[ァ-ヶー]*$/)
    }
  }

  checkErrorSex () {
    if (this.isCreateButtonClicked) {
      return this.patient.isMale === undefined
    }
  }

  checkErrorInsurance () {
    if (this.isCreateButtonClicked) {
      return this.patient.insurance === undefined
    }
  }

  checkErrorTel () {
    if (this.isNewPage()) {
      return this.isCreateButtonClicked && (!this.patient.telephone || !this.patient.telephone.match(/^[0-9]+$/) || !(this.patient.telephone.length === 10 || this.patient.telephone.length === 11))
    } else {
      return !this.patient.telephone || !this.patient.telephone.match(/^[0-9]+$/) || !(this.patient.telephone.length === 10 || this.patient.telephone.length === 11)
    }
  }

  checkErrorPostalCode3digit () {
    if (this.isNewPage()) {
      return this.isCreateButtonClicked && (this.postalCode3digit.length !== 3 || !this.postalCode3digit.match(/^[0-9]+$/))
    } else {
      return this.postalCode3digit.length !== 3 || !this.postalCode3digit.match(/^[0-9]+$/)
    }
  }

  checkErrorPostalCode4digit () {
    if (this.isNewPage()) {
      return this.isCreateButtonClicked && (this.postalCode4digit.length !== 4 || !this.postalCode4digit.match(/^[0-9]+$/))
    } else {
      return this.postalCode4digit.length !== 4 || !this.postalCode4digit.match(/^[0-9]+$/)
    }
  }

  showDeleteConfirm () {
    this.$store.commit('PatientModule/TOGGLE_DELETE_CONFIRM_MODAL', true)
  }

  updateErrorNum () {
    this.errorNum = 0

    if (this.checkErrorId()) {
      this.errorNum++
    }

    if (this.checkErrorFamilyName() || this.checkErrorGivenName()) {
      this.errorNum++
    }

    if (this.checkErrorKanaFamilyName() || this.checkErrorKanaGivenName()) {
      this.errorNum++
    }

    if (this.checkErrorSex()) {
      this.errorNum++
    }

    if (this.checkErrorInsurance()) {
      this.errorNum++
    }

    if (this.checkErrorTel()) {
      this.errorNum++
    }

    if (this.checkErrorPostalCode3digit() || this.checkErrorPostalCode4digit()) {
      this.errorNum++
    }
  }

  beforeUpdate () {
    if (this.isLoad) {
      this.updateErrorNum()
      return
    }
    if (!this.isNewPage()) {
      this.postalCode3digit = this.patient.postalCode && this.patient.postalCode.slice(0, 3)
      this.postalCode4digit = this.patient.postalCode && this.patient.postalCode.slice(-4)
      this.birthYear = this.patient.birthday && parseInt(this.patient.birthday.toString().split('-')[0])
      this.birthMonth = this.patient.birthday && parseInt(this.patient.birthday.toString().split('-')[1])
      this.birthDate = this.patient.birthday && parseInt(this.patient.birthday.toString().split('-')[2])
      this.isLoad = true
    }
  }
}
