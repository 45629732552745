
import { ROUTE_NAME_HOME } from '@/router'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DeleteCompleteModal extends Vue {
  @Prop()
  public show!: boolean

  close () {
    this.$store.commit('PatientModule/TOGGLE_DELETE_COMPLETE_MODAL', false)
    this.$router.push({ name: ROUTE_NAME_HOME })
  }
}
