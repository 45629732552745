
import { Component, Vue } from 'vue-property-decorator'
import NavigationBar from '../components/NavigationBar.vue'
import Footer from '../components/Footer.vue'
import PatientEdit from '@/components/patientEdit/PatientEdit.vue'
import { getModule } from 'vuex-module-decorators'
import PatientModule from '@/store/modules/PatientModule'
import { PatientAttributes } from 'api/models'

@Component({
  components: {
    NavigationBar,
    PatientEdit,
    Footer
  }
})
export default class PatientEditView extends Vue {
  patientModule = getModule(PatientModule, this.$store)

  get isLoading () { return this.patientModule.isLoading }

  async created (): Promise<void> {
    await this.$store.commit('PatientModule/SET_IS_LOADING', false)
  }

  newPatient: Partial<PatientAttributes> = {
    id: '',
    givenName: '',
    kanaGivenName: '',
    familyName: '',
    kanaFamilyName: '',
    birthday: undefined,
    isMale: undefined,
    telephone: '',
    memo: '',
    postalCode: '',
    insurance: undefined
  }
}
